import { useState, useEffect } from 'react';
import { useAccount, useWaitForTransactionReceipt, useWriteContract, useReadContract } from 'wagmi';
import { parseEther, formatEther } from 'viem';
import { mainnet, sepolia } from 'viem/chains';
import { useDialog } from '../../../dialog/hooks/use-dialog';
import { useDatabase } from '../../../db/hooks/use-database';
import { useTurnstile } from '../../../cloudflare/hooks/use-turnstile';
import { ANYONE_TOKEN, CONTRACT_ADDRESS, MAINNET_ANYONE_TOKEN } from '../../../../constants/env-configs';
import { ABI } from '../../../../constants/abi';
import { ANYONE_ABI } from '../../../../constants/anyoneABI';
import { mainAccount } from '../../../../constants/web3-config';

export function useClaim() {
  const { address } = useAccount();
  const { closeDialog: closeFundDialog, isDialogOpen: isFundDialogOpen, openDialog: openFundDialog } = useDialog();
  const { isAllowed, fundsCheckPassed, updateFunds, checkIPRateLimit, checkFundsLimit } = useDatabase();
  const [toAddress, setToAddress] = useState<`0x${string}` | string>('');
  const [amount, setAmount] = useState<number>(100);
  const [etherAmount, setEtherAmount] = useState<number>(0.03);
  const [dbError, setDBError] = useState<string | null>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const { siteKey, isVerified, verifyTurnstile, resetTurnstile } = useTurnstile();

  const { writeContract, data: writeContractData, error, isPending, reset, status } = useWriteContract();

  const { data: tokens, isLoading: isTokenLoading } = useReadContract({
    account: mainAccount,
    chainId: mainnet.id,
    address: MAINNET_ANYONE_TOKEN,
    abi: ANYONE_ABI,
    functionName: 'balanceOf',
    args: [toAddress],
    query: { refetchInterval: 1000 },
  });

  const [storedHash, setStoredHash] = useState<`0x${string}` | null>(null);

  useEffect(() => {
    const hash = localStorage.getItem('transactionHash');
    const oldToAddress = localStorage.getItem('toAddress') || '';
    const oldAmount = parseInt(localStorage.getItem('amount') || '100');
    if (hash) {
      setStoredHash(hash as `0x${string}`);
      setToAddress(oldToAddress as `0x${string}`);
      setAmount(oldAmount);
      openFundDialog();
    }
  }, []);

  const { data: receiptData, error: receiptError, isLoading: isConfirming, isSuccess: isConfirmed, isError: isReceiptError, status: receiptStatus } = useWaitForTransactionReceipt({
    chainId: sepolia.id,
    hash: storedHash || writeContractData,
    pollingInterval: 500
  });

  useEffect(() => {
    const updateFundsOnConfirmation = async () => {
      if (isConfirmed && toAddress && amount && storedHash) {
        try {
          await updateFunds(toAddress, amount);
          // closeFundDialog();
        } catch (updateError) {
          setDBError('Something went wrong. Please try again later.');
        }
      }

      // if (isConfirmed) {
      //   localStorage.removeItem('transactionHash');
      //   // setStoredHash(null);
      // }
    };

    updateFundsOnConfirmation();
  }, [isConfirmed, toAddress, amount]);

  const fundAccount = async () => {
    openFundDialog();

    const turnstileVerified = await verifyTurnstile(turnstileToken as string);
    if (!turnstileVerified) {
      setDBError("CAPTCHA verification failed. Please try again.");
      return;
    }

    if (isNaN(amount)) {
      setDBError("Tokens to receive cannot be empty");
      return;
    }

    if (tokens !== undefined) {
      const tokenBalance = Number(formatEther(tokens as bigint));
      
      if (tokenBalance < 100) {
        setDBError("The receiver must hold 100 tokens or more on mainnet");
        return;
      }
    } else if (!isTokenLoading) {
      setDBError("Unable to fetch token balance. Please try again.");
      return;
    }

    const ipCheckPassed = await checkIPRateLimit();
    const fundsCheckPassed = await checkFundsLimit(toAddress, amount);

    if (!ipCheckPassed || !fundsCheckPassed) {
      setDBError(
        !ipCheckPassed
          ? 'You have exceeded the rate limit. Try again later'
          : 'Funds limit exceeded. Try again later.'
      );
      // openFundDialog();
      return;
    }
    
    writeContract({
      account: mainAccount,
      chainId: sepolia.id,
      address: CONTRACT_ADDRESS,
      abi: ABI,
      functionName: 'fund',
      args: [toAddress, parseEther(amount.toString()), parseEther(etherAmount.toString())],
    });
  };

  useEffect(() => {
    if (writeContractData) {
      localStorage.setItem('transactionHash', writeContractData);
      localStorage.setItem('toAddress', toAddress);
      localStorage.setItem('amount', amount.toString());
      setStoredHash(writeContractData);
    }
  }, [writeContractData]);

  return {
    toAddress,
    setToAddress,
    amount,
    setAmount,
    etherAmount,
    setEtherAmount,
    fundAccount,
    isPending,
    isConfirming,
    isConfirmed,
    dbError,
    error,
    status,
    reset,
    closeFundDialog,
    setStoredHash,
    isFundDialogOpen,
    setTurnstileToken,
    turnstileToken,
  };
}