import { CircularProgress } from '@mui/material';
import { Dialog, DialogProps } from '../dialog/dialog';
import styles from './fund-dialog.module.scss';
import { Button } from '../../../ui/components/button/button';

import { BaseError } from 'wagmi';
import { useEffect } from 'react';

export interface FundDialogProps extends DialogProps {
  error: BaseError | null;
  isConfirmed: boolean;
  isConfirming: boolean;
  isPending: boolean;
  dbError: string | null;
}

export function FundDialog(props: FundDialogProps) {
  const { hide, visible, error, isConfirmed, isConfirming, isPending, dbError } = props;

  useEffect(() => {
    console.log('Error:', error);
  }, [error]);

  useEffect(() => {
    console.log('isConfirmed:', isConfirmed);
  }, [isConfirmed]);

  return (
    <Dialog
      hide={() => {
        if (isConfirming || isPending) {
          return;
        }
        return hide();
      }}
      visible={visible}
    >
      <div className={styles.container}>
        <div className={styles.title}>Fund Wallet</div>
        <div className={styles.content}>
          {(!(isConfirmed||error||dbError)) && (
            <div className={styles.loadingContainer}>
              <div className={styles.text}>
                {isConfirming && 'Waiting for confirmation'}
                {isPending && 'Funding Wallet...'}
                {!isConfirming && !isPending && 'Funding Wallet...'}
              </div>
              <CircularProgress variant="indeterminate" />
            </div>
          )}
          {isConfirmed && <div className={styles.success}>Wallet Successfully Funded</div>}
          {error && (
            <div className={styles.error}>
              {(error as BaseError).shortMessage || error.message}
            </div>
          )}
          {dbError && <div className={styles.error}>{dbError}</div>}
          {(isConfirmed || error || dbError) && <Button onClick={hide}>Close</Button>}
        </div>
      </div>
    </Dialog>
  );
}
